@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

.slick-dots li.slick-active button:before {
  color: #27aae1 !important;
}

.text-gradient {
  position: relative;
  display: inline-block;
  background: linear-gradient(180.14deg, #b07cec 21.25%, #3b7bb9 79.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-gradient::before {
  content: attr(data-text);
  /* Menampilkan teks yang sama */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  color: rgba(0, 0, 0, 0.9);
  /* Warna shadow */
  filter: blur(10px);
  /* Membuat efek blur untuk shadow */
  transform: translate(2px, 2px);
  /* Offset untuk shadow */
}

/* Customize the dots */
.slick-dots li button:before {
  font-size: 10px;
  color: #a8a8a8 !important;
  /* Inactive dot color */
}

.slick-dots li.slick-active button:before {
  color: #b07cec !important;
  /* Active dot color */
}

@media (max-width: 768px) {
  html,
  body {
    overflow-x: hidden;
  }
}

@media (max-width: 576px) {
  html,
  body {
    overflow-x: hidden;
  }
}

.hover-image:hover + .hover-box {
  opacity: 1;
}

.hover-image:hover {
  opacity: 0;
}


@media (min-width: 769px) { /* Berlaku untuk layar lebih besar dari 768px */
  .slick-slider {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0 20px; */
  }
  .slick-list {
    position: relative;
    display: block !important;
    overflow: hidden;
    /* padding: 100px !important; */
    width: 100%;
    height: 600px;
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
  }
  .slick-slide div {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .slick-slide .image {
    object-fit: cover;
    border-radius: 2px;
    min-width: 150px !important;
    width: 80%;
  }
  .slick-slide div div span {
    margin-top: 10px;
    width: inherit;
    text-align: center;
    font-size: 0.75rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #111;
  }

  .slick-slide:not(.slick-active) {
    position: relative;
    z-index: 997;
  }
  .slick-slider {
    overflow: visible; /* Penting agar scaling tidak memotong elemen */
    perspective: 1000px; /* Tambahan untuk efek lebih menarik */
  }

  .slick-slide {
    position: relative;
    transition: transform 0.25s ease, z-index 0.25s ease;
  }

  .slick-slide.slick-active:not(.slick-current) {
    z-index: 998;
    transform: scale(1.3);
  }

  .slick-slide.slick-active.slick-current {
    z-index: 999;
    transform: scale(1.6);
  }
  .slick-slide.slick-active:not(.slick-current) .image,
  .slick-slide.slick-active.slick-current .image,
  .slick-slide:not(.slick-active) .image {
    width: 100% !important;
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .custom-prevArrow,
  .custom-nextArrow {
    position: relative;
    z-index: 99999;
    top: -10px;
  }
  .custom-prevArrow {
    left: -10px;
  }
  .custom-nextArrow {
    right: -10px;
  }
}

/* .custom-prevArrow:hover,
.custom-nextArrow:hover {
  fill: red;
  cursor: pointer;
} */
